/*
const login = "customerservice@doctor.one"
const password = "!QAZ2wsx" // !QAZ2wsx
*/


const baseApiUrl =  "https://api.doctor.one/api"
const firebaseEnv = "PROD_ENV"
const firebaseConfig = {
  apiKey: "AIzaSyBy2MllMV2ZIdRS4ggQbHKTDBXrcLBf9Zw",
  authDomain: "boreal-sweep-322513.firebaseapp.com",
  projectId: "boreal-sweep-322513",
  storageBucket: "boreal-sweep-322513.appspot.com",
  messagingSenderId: "401162542380",
  appId: "1:401162542380:web:819bcfc960efd77e0da089",
  measurementId: "G-N2GQBWT6W0"
};



/*
const baseApiUrl = "http://localhost:3000" // "https://doctor-one-test.appspot.com/api"
const firebaseEnv = "TEST_ENV"
const firebaseConfig = {
    apiKey: "AIzaSyBi1aqSxYywdKx7oEBfr4OXY-IAWEC_lnU",
    authDomain: "doctor-one-test.firebaseapp.com",
    projectId: "doctor-one-test",
    storageBucket: "doctor-one-test.appspot.com",
    messagingSenderId: "192562183006",
    appId: "1:192562183006:web:2c854d7b2d48e5d75c6dcf",
    measurementId: "G-P422YS3ZNW"
  };
*/
  export { firebaseConfig, baseApiUrl, firebaseEnv}